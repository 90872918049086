/** @format */

import React, { lazy, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

const HomeCard = lazy(() => import("./Home/index"));

const HomeCardDemo = lazy(() => import("./Demo/index"));
const HomeCardSuccess = lazy(() => import("./Demo/Form/Success"));

const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./TermsAndConditions'));
const CookiePolicy = lazy(() => import('./CookiePolicy'));
const HomeCardThanks = lazy(() => import("./Thanks/index"));

const Router = () => {
  const { listen } = useHistory();
  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      window.gtag("config", "GTM-TC9KTLT", {
        page_path: location.pathname,
      });
    });
    return unlisten;
  }, [listen]);

  return (
    <Switch>
      <Route exact path="/thank-you-demo">
        <HomeCardThanks />
      </Route>
      <Route exact path="/">
        <HomeCard />
      </Route>
      <Route exact path="/demo/step-success">
        <HomeCardSuccess />
      </Route>
      <Route exact path="/demo">
        <HomeCardDemo />
      </Route>
      <Route exact path="/demo/*">
        <HomeCardDemo />
      </Route>
      <Route exact path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route exact path="/terms-and-conditions">
        <TermsAndConditions />
      </Route>
      <Route exact path="/cookie-policy">
        <CookiePolicy />
      </Route>
    </Switch>
  );
};

export default Router;
